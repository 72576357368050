import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm';
import { router } from './router/index';
import { store } from './store/index';

import App from './App.vue'
import axios from "axios";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : "http://localhost:8989";

const axios_instance = axios.create({
    baseURL: API_URL,
});

const serialize_param = function (obj, ignore_null_empty = true) {
    /*
    if (typeof obj === 'undefined' || obj == null) {
        return "";
    }
    */
    const arr = Array.isArray(obj) ? obj : [obj];
    const str = arr
        .filter(v => (v != null))
        .reduce((acc, i) => {
            const tmp = [];
            for (const [k, v] of Object.entries(i)) {
                if (ignore_null_empty && (v == null || v === '')) {
                    continue;
                }
                tmp.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
            }
            return [...acc, ...tmp];
        }, [])
        .join("&");
    return `?${str}`;
};
class XIOBase {
    constructor(base_path) {
        this.base_path = base_path;
    }

    get(dict = null) {
        const params = serialize_param(dict);
        return axios_instance.get(`${this.base_path}${params}`);
    }

    get_one(id, dict = null) {
        const params = serialize_param(dict);
        return axios_instance.get(`${this.base_path}/${id}${params}`);
    }

    post(dict) {
        const form = new FormData();
        for (const [key, value] of Object.entries(dict)) {
            if (Array.isArray(value)) {
                value.forEach(v => {
                    form.append(`${key}`, v);
                });
            } else {
                form.append(`${key}`, value);
            }
        }
        return axios_instance.post(`${this.base_path}`, form);
    }

    put(id, dict) {
        const form = new FormData();
        for (const [key, value] of Object.entries(dict)) {
            form.append(`${key}`, value);
        }
        return axios_instance.put(`${this.base_path}/${id}`, form);
    }

    delete(id) {
        return axios_instance.delete(`${this.base_path}/${id}`);
    }
}
function get_country() {
    return axios.get("https://api.ip.pe.kr/json");
}

const app = createApp(App)
app.use(router)
app.use(
    createGtm({
        id: 'GTM-5WR87R4D', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        nonce: '2726c7f26c', // Will add `nonce` to the script tag
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // Don't trigger events for specified router names (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    }),
)
app.use(store)
app.config.globalProperties.$axios_instance = axios_instance;
app.config.globalProperties.$send_email = new XIOBase('/v2/auth/send-email-henergy');
app.config.globalProperties.$get_country = get_country;
app.mount('#app')