import { createStore } from 'vuex';

export const store = createStore({
    state () {
      return {
        device_width: window.innerWidth
      }
    },
    mutations: {
        change_device_width (state, width) {
            state.device_width = width;
        }
    },
    actions: {
        change_width ({ commit }) {
            commit('change_device_width')
        }
    }
  })