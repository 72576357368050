import {
    createRouter, createWebHistory
} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/components/Main'), // 동적 import
    },
    {
        path: '/notice',
        name: 'notice',
        component: () => import('@/components/Notice'), // 동적 import
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/"
    },
]

export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        const offsetY = window.screen.width < 720 ? 37 : 0
        console.log("offsetY", offsetY)
        console.log(to)
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: offsetY
            }
        }
    },
});